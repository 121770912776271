import React from "react";

import { styled } from "@hiyllo/ux/styled";
import { MailUI } from "@hiyllo/omni-mail";
import { Button } from "@hiyllo/ux/button";
import { useNavigate } from "@hiyllo/omni-router";
import { useShowAlert, useShowDialog } from "@hiyllo/ux/dialogs";
import { usePushUndo } from "@hiyllo/omni-continuity";
import { useUploadFile } from "@hiyllo/omni-user-files";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { type EditorRef } from "@hiyllo/editor/v2/editor-v2";

import * as ComposeEmailBP from "../../../blueprints/mail/compose-email";
import * as SaveDraftBP from "../../../blueprints/mail/save-draft";
import * as ArchiveEmailBP from "../../../blueprints/mail/archive-email";
import * as ListMailboxesBP from "../../../blueprints/mail/list-mailboxes";
import * as CancelSendBP from "../../../blueprints/mail/cancel-send";

import { seamlessClient } from "../../../seamless-client";
import { Features } from "../../../types/navigation/features";
import { BuiltInFolderEnum } from "../../../types/mail/organization/builtin-folders";
import { MailMessage, type MailMessageAttachment } from "../../../types/mail/message/message";
import { useConfig } from "../../../platform/config/config-context";
import { useSettingQuery } from "../../../platform/settings";
import { Editor } from "../../stuff/documents/v2/editor-v2";
import { serializeSlateToHTML } from "../../stuff/documents/v2/serializer";
import { Descendant } from "slate";
import { DescendantType } from "../../stuff/documents/v2/types";
import { KeyCombination, KeyCombinationHint, useHotkey } from "@hiyllo/ux/hotkeys";
import { UseMoopsyQueryRetValAny } from "@moopsyjs/react/main";
import { ReactEditor } from "slate-react";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";


const Container = styled<"div", { noPadding?: boolean }>(
  "div",
  ({ noPadding }) => ({
    display: "flex",
    flexDirection: "column",
    padding: noPadding ? 0 : 8,
    height: noPadding ? "100%" : "calc(100% - 16px)",
  }),
);

const ContentWindow = styled("div", ({ $theme }) => ({
  background: $theme.background2,
  display: "flex",
  flexDirection: "column",
  padding: 8,
  borderRadius: 8,
  gap: 8,
}));

const ContentPill = styled("div", ({ $theme }) => ({
  background: $theme.background2,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: 8,
  borderRadius: 8,
  gap: 8,
  cursor: "pointer",
}));

const FlexStartRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 8,
});

const FormattedDropdown = React.memo(function FormattedDropdown(props: {
  label: string;
  value: string;
  options: Array<{ value: string; label: string }>;
  onChangeValue: (value: string) => void;
}): JSX.Element {
  const toRef = React.useRef<HTMLSelectElement>(null);

  return (
    <MailUI.HeaderInputContainer
      onPress={() => toRef.current?.focus()}
      focusable={false}
      // @ts-expect-error ---
      activeOpacity={1}
    >
      <MailUI.HeaderInputLabel>{props.label}</MailUI.HeaderInputLabel>
      <label>
        <select
          ref={toRef}
          value={props.value}
          onChange={(e) => {
            props.onChangeValue(e.currentTarget.value);
          }}
          style={{
            background: "transparent",
            color: "white",
            border: "none",
            padding: 0,
            margin: 0,
            flexGrow: 1,
            outline: "none",
            WebkitAppearance: "none",
          }}
        >
          {props.options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      </label>
    </MailUI.HeaderInputContainer>
  );
});

const sendCombination: KeyCombination = ["meta", "Enter"];

export const ComposeView = React.memo(function ComposeView(props: {
  noPadding?: boolean;
  inReplyTo?: string | null;
  to?: string[];
  cc?: string[];
  bcc?: string[];
  subject?: string;
  forward?: MailMessage;
  html?: string;
  draftMessageUUID?: string | null;
  initialContents?: DescendantType[] | null;
  querySideEffects?: UseMoopsyQueryRetValAny[];
  autofocus?: boolean;
}): JSX.Element {
  const undoSendMutation =
    seamlessClient.useMutation<CancelSendBP.Plug>(CancelSendBP);
  const composeEmailMutation =
    seamlessClient.useMutation<ComposeEmailBP.Plug>(ComposeEmailBP);
  const saveDraftMutation =
    seamlessClient.useMutation<SaveDraftBP.Plug>(SaveDraftBP);
  const archiveEmailMutation =
    seamlessClient.useMutation<ArchiveEmailBP.Plug>(ArchiveEmailBP, {
      querySideEffects: [],
    });
  const listMailboxesQuery =
    seamlessClient.useQuery<ListMailboxesBP.Plug>(
      ListMailboxesBP,
      null,
    );
  const [from, setFrom] = React.useState<string>("");
  React.useEffect(() => {
    setFrom((v) =>
      v === "" ? listMailboxesQuery.data?.mailboxes[0].uuid ?? "" : v,
    );
  }, [listMailboxesQuery.data]);
  const [to, setTo] = React.useState<string[]>(props.to ?? []);
  const [cc, setCc] = React.useState<string[]>(props.cc ?? []);
  const [bcc, setBcc] = React.useState<string[]>(props.bcc ?? []);
  const [subject, setSubject] = React.useState<string>(props.subject ?? "");

  const editorRef = React.useRef<EditorRef>(null);
  const [text, setText] = React.useState<string>("");
  const navigate = useNavigate();
  const [attachments, setAttachments] = React.useState<MailMessageAttachment[]>(
    [],
  );
  const [uploadingAttachments, setUploadingAttachments] = React.useState<
    Array<{ filename: string; id: string; progress: number }>
  >([]);
  const uploadFile = useUploadFile();
  const dialog = useShowDialog();
  const pushUndo = usePushUndo();
  const draftMessageUUIDRef = React.useRef<string | null>(
    props.draftMessageUUID ?? null,
  );
  const saveDraftTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const getEmail = React.useCallback(() => {
    const editor = editorRef.current;

    if (editor?.editor == null) {
      throw new Error("Quill editor not initialized");
    }

    const htmlString = serializeSlateToHTML(editor.editor.children);


    const html = `<div><style>p { margin-block-start: 0px; margin-block-end: 0px; margin-top: 0px; margin-bottom: 0px; } </style>${props.forward
      ? `<div>${htmlString}</div><br><br>----------Forwarded Message----------<br><br><div>${props.forward.base.html
      }</div>`
      : htmlString}</div>`;

    const email = {
      to,
      cc,
      bcc,
      subject,
      html,
      text: props.forward ? null : "inop",
      inReplyTo: props.inReplyTo ?? null,
      attachments: [...attachments, ...(props.forward?.attachments ?? [])],
    };

    return { email, htmlString };
  }, [attachments, bcc, cc, props.forward, props.inReplyTo, subject, to]);
  const getEmailRef = React.useRef(getEmail);
  getEmailRef.current = getEmail;
  const [contents, setContents] = React.useState<DescendantType[]>([]);
  const contentsRef = React.useRef<DescendantType[]>(contents);
  contentsRef.current = contents;

  const savingRef = React.useRef(false);

  const saveDraft = React.useCallback(() => {
    if (savingRef.current) return console.debug(`[Mail] Not saving draft as save already in progress...`);
    savingRef.current = true;
    console.debug(`[Mail] Saving draft...`);
    const { email, htmlString } = getEmailRef.current();
    const isEmpty = (htmlString.length <= 15) && email.attachments.length < 1;

    if (isEmpty && (draftMessageUUIDRef.current == null || draftMessageUUIDRef.current === "")) {
      savingRef.current = false;
      return console.debug(`[Mail] Not saving draft html length is <= 15, and no attachments, and no draft UUID`, {
        htmlString: htmlString.length,
        attachments: email.attachments.length,
        draftMessageUUID: draftMessageUUIDRef.current
      });
    }

    saveDraftMutation
      .call({
        email,
        messageUUID: draftMessageUUIDRef.current === "" ? null : draftMessageUUIDRef.current,
        draftContents: contentsRef.current,
      })
      .then((res) => {
        console.debug(`[Mail] Saved Draft "${res.messageUUID}"`);
        draftMessageUUIDRef.current = res.messageUUID;
      })
      .catch((err) => {
        console.error(`[Mail] Error saving draft:`, err);
      })
      .finally(() => {
        savingRef.current = false;
      });
  }, [saveDraftMutation]);
  const saveDraftRef = React.useRef(saveDraft);
  saveDraftRef.current = saveDraft;
  const initializedRef = React.useRef(false);

  React.useEffect(() => {
    if (initializedRef.current === false) {
      initializedRef.current = true;
      return;
    }

    if (saveDraftTimeoutRef.current != null) {
      clearTimeout(saveDraftTimeoutRef.current);
    }

    console.log(`[Mail] Draft changed, saving in 750ms...`);
    saveDraftTimeoutRef.current = setTimeout(() => {
      saveDraftRef.current();
    }, 750);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  React.useEffect(() => {
    if (attachments.length > 0) {
      saveDraftRef.current();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  const showAlert = useShowAlert();
  const [sendLoading, setSendLoading] = React.useState(false);
  const sendingRef = React.useRef(false);

  const send = React.useCallback(
    async (archive?: boolean) => {
      if (sendingRef.current) {
        return;
      }

      sendingRef.current = true;
      setSendLoading(true);
      if (saveDraftTimeoutRef.current != null) {
        clearTimeout(saveDraftTimeoutRef.current);
        saveDraftTimeoutRef.current = null;
      }

      const { email } = getEmail();

      if (uploadingAttachments.length > 0) {
        const shouldContinue = await new Promise((resolve) => {
          void dialog({
            title: "Attachments still uploading",
            message:
              "Some attachments are still uploading, are you sure you want to send?",
            onConfirm: () => {
              resolve(true);
            },
            onCancel: () => {
              resolve(false);
            },
          });
        });

        if (!shouldContinue) {
          setSendLoading(false);
          sendingRef.current = false;
          return;
        }
      }

      const params: ComposeEmailBP.Plug["params"] = {
        email,
        fromDraft: draftMessageUUIDRef.current === "" ? null : draftMessageUUIDRef.current,
      };

      if (from != null) {
        params.mailboxUUID = from;
      }

      const sendDate = Date.now();

      try {
        const res = await composeEmailMutation.call(params);

        if (archive && props.inReplyTo != null) {
          try {
            await archiveEmailMutation.call({
              uuid: props.inReplyTo,
            });
          }
          catch (err: any) {
            //
          }
        }

        if (archive || props.inReplyTo == null) {
          navigate({
            feature: Features.mail,
            params: {
              view: "mail",
              folder: BuiltInFolderEnum.inbox,
            },
          });
        }

        for (const qse of props.querySideEffects ?? []) {
          await qse.refresh({ subtle: true });
        }

        pushUndo({
          label: `Sending "${params.email.subject}"...`,
          undoableTill: new Date(sendDate + 1000 * 15),
          fn: async () => {
            await undoSendMutation.call({
              uuid: res.messageUUID,
            });
          },
        });
      }
      catch (err: any) {
        void showAlert({
          title: "Error sending email",
          message: err.message,
        });
      }
      finally {
        setSendLoading(false);
        sendingRef.current = false;
      }
    },
    [archiveEmailMutation, composeEmailMutation, dialog, from, getEmail, navigate, props.inReplyTo, props.querySideEffects, pushUndo, showAlert, undoSendMutation, uploadingAttachments.length],
  );

  const onSelectAttachment = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = [...(e.target.files ?? [])];
      // @ts-expect-error zzz
      e.target.value = null;

      if (files != null) {
        const totalSize =
          Array.from(files).reduce((acc, file) => acc + file.size, 0) +
          attachments.reduce((acc, attachment) => acc + attachment.size, 0);

        if (totalSize > 25 * 1000 * 1000) {
          void dialog({
            title: "Attachment too large",
            message:
              "You cannot send more than 25MB in attachments in a single email",
            onConfirm: () => {
              //
            },
          });
          return;
        }

        for (const file of files) {
          const id = Math.random().toString();

          setUploadingAttachments((uploadingAttachments) => [
            ...uploadingAttachments,
            {
              filename: file.name,
              id,
              progress: 0,
            },
          ]);

          void uploadFile(file, {
            onProgress: (progress) => {
              setUploadingAttachments((uploadingAttachments) =>
                uploadingAttachments.map((ua) =>
                  ua.id === id ? { ...ua, progress } : ua,
                ),
              );
            },
          }).then(({ fsId }) => {
            setUploadingAttachments((uploadingAttachments) =>
              uploadingAttachments.filter((ua) => ua.id !== id),
            );
            setAttachments((attachments) => [
              ...attachments,
              {
                fsId,
                filename: file.name,
                size: file.size,
              },
            ]);
          });
        }
      }
    },
    [attachments, dialog, uploadFile],
  );

  React.useEffect(() => {
    setTimeout(() => {
      const htmlString = serializeSlateToHTML(editorRef.current?.editor?.children ?? []);
      setText(htmlString);
    }, 300);
  }, []);

  const signatureSettingQuery = useSettingQuery("email/signature");

  const canSend =
    (to.length > 0 || cc.length > 0 || bcc.length > 0) &&
    (text.length > 0 || props.forward != null) &&
    subject.length > 0;
  const config = useConfig();

  const onHotkeySend = React.useCallback(() => {
    if (props.inReplyTo == null) {
      void send();
    }
    else {
      void send(true);
    }
  }, [props.inReplyTo, send]);
  useHotkey(sendCombination, onHotkeySend);

  React.useEffect(() => {
    setTimeout(() => {
      if (props.autofocus === true && editorRef.current?.editor != null) {
        ReactEditor.focus(editorRef.current.editor);
      }
    }, 300);
  }, []);

  if (signatureSettingQuery.isLoading) {
    return <div />;
  }

  return (
    <Container noPadding={props.noPadding}>
      <ContentWindow>
        <FormattedDropdown
          label="From:"
          value={from}
          options={
            listMailboxesQuery.data?.mailboxes.map((m) => ({
              value: m.uuid,
              label: m.address + (config.emailDomain ?? ""),
            })) ?? []
          }
          onChangeValue={setFrom}
        />
        <div style={{ height: 1, background: "#232323" }} />
        <MailUI.MutliEmailInput label="To:" value={to} onChangeValue={setTo} />
        <div style={{ height: 1, background: "#232323" }} />
        <MailUI.MutliEmailInput label="Cc:" value={cc} onChangeValue={setCc} />
        <div style={{ height: 1, background: "#232323" }} />
        <MailUI.MutliEmailInput
          label="Bcc:"
          value={bcc}
          onChangeValue={setBcc}
        />
        <div style={{ height: 1, background: "#232323" }} />
        <MailUI.SubjectInput value={subject} onChangeText={setSubject} />
      </ContentWindow>

      <div style={{ height: 8, flexShrink: 0 }} />

      <FlexStartRow style={{ display: "flex", flexWrap: "wrap" }}>
        <label>
          <input
            type="file"
            onChange={onSelectAttachment}
            multiple
            style={{ display: "none" }}
          />
          <ContentPill>
            <FontAwesomeIcon icon={faCloudUpload} />
            <div>Add Attachment</div>
          </ContentPill>
        </label>
        {uploadingAttachments.map((ua) => (
          <ContentPill key={ua.id}>
            <div>Uploading {ua.filename}</div>
            <div>{(ua.progress * 100).toFixed(0)}%</div>
          </ContentPill>
        ))}
        {attachments.map((ua) => (
          <ContentPill key={ua.fsId}>
            <div>
              {ua.filename} {(ua.size / 1000 / 1000).toFixed(2)}mb
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAttachments((attachments) =>
                  attachments.filter((a) => a.fsId !== ua.fsId),
                );
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </div>
          </ContentPill>
        ))}
      </FlexStartRow>

      <div style={{ height: 8, flexShrink: 0 }} />

      <div style={{ marginRight: -16, height: 0, flexGrow: 1, width: "100%" }}>
        <Editor
          ref={editorRef}
          initialHTML={props.html ?? (signatureSettingQuery.data != null ? `<div><p></p><br><br>${signatureSettingQuery.data}</div>` : "")}
          initialContents={{
            v2: true,
            descendants: props.initialContents ?? [
              {
                type: "paragraph",
                children: [{ text: "" }],
              },
            ]
          }}
          onValueChanged={(editor) => {
            setContents(editor.descendants);
            setText(serializeSlateToHTML(editor.descendants as Descendant[]));
          }}
          isSaving={false}
          noPadding
          contentSuffix={
            props.forward ? (
              <div>Forwarded Message will be attached at the bottom</div>
            ) : undefined
          }
        />
      </div>

      <AnimateChangeInHeight>
        {canSend ? (
          <div style={{ flexShrink: 0, paddingTop: 8 }}>
            {props.inReplyTo == null ? (
              <FlexStartRow>
                <Button
                  label={
                    <>
                      Send
                      <KeyCombinationHint
                        combination={sendCombination}
                      />
                    </>
                  }
                  onClick={() => send()}
                  isLoading={composeEmailMutation.isLoading}
                />
              </FlexStartRow>
            ) : (
              <FlexStartRow>
                <Button
                  label={
                    <>
                      Archive & {props.forward == null ? "Reply" : "Forward"}
                      <KeyCombinationHint
                        combination={sendCombination}
                      />
                    </>
                  }
                  onClick={() => send(true)}
                  isLoading={sendLoading}
                />
                <Button
                  label={props.forward == null ? "Reply" : "Forward"}
                  isSecondary
                  onClick={() => send()}
                  isLoading={sendLoading}
                />
                {saveDraftMutation.isLoading ? <LoadingSpinner /> : null}
              </FlexStartRow>
            )}
          </div>
        ) : null}
      </AnimateChangeInHeight>
    </Container>
  );
});
