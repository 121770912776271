import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { Typography } from "@hiyllo/ux/typography";
import { useGetProject } from "../hooks/use-get-project";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { Input } from "@hiyllo/ux/input";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { HIYLLO_DEFAULT_STATUSES, TaskColorsEnum, type TaskProject } from "../../../types/tasks/tasks-organization";
import { faCheck, faPlus, faTrash, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { useUpdateProjectName } from "../hooks/use-update-project-name";
import { useArchiveProject } from "../hooks/use-archive-project";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useShowAlert, useShowConfirmationDialog, useShowDialog } from "@hiyllo/ux/dialogs";
import { RWAccessControlView } from "../../continuity/access/access-control-view";
import * as UpdateProjectAccessBP from "../../../blueprints/tasks/update-project-access";
import * as UpdateProjectStatusesBP from "../../../blueprints/tasks/projects/update-project-statuses";
import * as ListProjectDefaultsBP from "../../../blueprints/tasks/projects/list-project-defaults";
import { seamlessClient } from "../../../seamless-client";
import { type MoopsyError } from "@moopsyjs/core";
import { useNavigate } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { Button, ButtonVariant } from "@hiyllo/ux/button";
import { Structure } from "@hiyllo/ux/structure";
import { NymblIcon } from "@hiyllo/icons/main";
import { EditableTableCell, Table, TableCell, TableRow } from "@hiyllo/ux/table";
import { TaskColors } from "@hiyllo/omni-tasks/main";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";
import { InfoCard } from "../../../ux/info-card";
import { Spacer } from "../../../ux/alpha";

const Container = styled("div", ({ $theme }) => ({
  fontFamily: "hiyllo",
  padding: 10,
}));

const Row = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
}));

const EditProjectName = React.memo(function EditProjectName(props: {
  project: TaskProject;
  projectQuery: UseMoopsyQueryRetValAny;
  projectsQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const updateProjectNameMutation = useUpdateProjectName({
    querySideEffects: [props.projectQuery, props.projectsQuery],
  });
  const [editedName, setEditedName] = React.useState<string>(
    props.project.name,
  );

  const saveProjectName = React.useCallback(() => {
    void updateProjectNameMutation.call({
      uuid: props.project.uuid,
      name: editedName,
    });
  }, [editedName, props.project.uuid, updateProjectNameMutation]);

  const changed = editedName !== props.project.name;

  return (
    <>
      <Typography.Label>Project Name</Typography.Label>
      <Row>
        <Input value={editedName} onChangeValue={setEditedName} />
        {changed ? (
          <CircleButton
            size={35}
            onClick={saveProjectName}
            icon={faCheck}
            isLoading={updateProjectNameMutation.isLoading}
          />
        ) : null}
      </Row>
    </>
  );
});

export const ProjectSettingsView = React.memo(
  function ProjectSettingsView(props: {
    projectUUID: string;
    projectsQuery: UseMoopsyQueryRetValAny;
  }): JSX.Element {
    const projectQuery = useGetProject({ uuid: props.projectUUID });
    const listProjectDefaultsQuery = seamlessClient.useQuery<ListProjectDefaultsBP.Plug>(ListProjectDefaultsBP, {
      projectUUID: props.projectUUID,
    });
    const archiveProjectMutation = useArchiveProject({
      querySideEffects: [props.projectsQuery],
    });
    const updateProjectStatusesMutation = seamlessClient.useMutation<UpdateProjectStatusesBP.Plug>(
      UpdateProjectStatusesBP,
      { querySideEffects: [listProjectDefaultsQuery] }
    );
    const updateProjectAccessMutation =
      seamlessClient.useMutation<UpdateProjectAccessBP.Plug>(
        UpdateProjectAccessBP,
        { querySideEffects: [projectQuery] },
      );
    const showConfirmDialog = useShowConfirmationDialog();
    const navigate = useNavigate();

    const archiveProject = React.useCallback(() => {
      void showConfirmDialog({
        title: "Archive Project",
        message:
          "Are you sure you want to archive this project? You will not be able to change any tasks in this project and it will only be accessible from search.",
      }).then((res) => {
        if (res) {
          void archiveProjectMutation.call({ projectUUID: props.projectUUID });
          navigate({
            feature: Features.tasks,
            params: null,
          });
        }
      });
    }, [
      archiveProjectMutation,
      navigate,
      props.projectUUID,
      showConfirmDialog,
    ]);

    const dialog = useShowDialog();

    const _statuses = listProjectDefaultsQuery.data?.statuses ?? HIYLLO_DEFAULT_STATUSES;
    const [statuses, setStatuses] = React.useState(_statuses);

    React.useEffect(() => {
      setStatuses(_statuses);
    }, [_statuses]);

    const showAlert = useShowAlert();

    const saveCustomStatuses = React.useCallback(() => {
      const newStatuses = statuses.map(s => {
        return {
          ...s,
          projectUUID: props.projectUUID,
        };
      });

      void updateProjectStatusesMutation.call({
        projectUUID: props.projectUUID,
        statuses: newStatuses,
      }).then(() => {
        setStatuses(_statuses);
      }).catch((err) => {
        void showAlert({
          title: "Error saving custom statuses",
          message: (err as MoopsyError).description,
        });
      });
    }, [_statuses, props.projectUUID, showAlert, statuses, updateProjectStatusesMutation]);

    console.log(">>>>", listProjectDefaultsQuery);

    if (projectQuery.isLoading || projectQuery.isError) {
      return <LoadingSpinnerFullView />;
    }

    return (
      <Container>
        <Structure.LColumn20>
          <Typography.Header>{projectQuery.data.project.name}</Typography.Header>

          <div>
            <EditProjectName
              project={projectQuery.data.project}
              projectQuery={projectQuery}
              projectsQuery={props.projectsQuery}
            />
          </div>

          <div>
            <Typography.Label>Project Access</Typography.Label>
            <RWAccessControlView
              rwAccess={projectQuery.data.project.access}
              onChangeValue={(access) => {
                console.log(117, access);
                void updateProjectAccessMutation
                  .call({
                    uuid: props.projectUUID,
                    access,
                  })
                  .catch((err) => {
                    dialog({
                      title: "Error updating project access",
                      message: (err as MoopsyError).description,
                      confirmLabel: "OK",
                      onConfirm: () => {
                        //
                      },
                    });
                  });
              }}
            />
          </div>

          {projectQuery.data.project.summary != null ?
            <div>
              <div>
                <NymblIcon width={12.5} color="white" /> <b>Nymbl Summary</b>
              </div>
              <div style={{ paddingTop: 5 }}>
                {projectQuery.data.project.summary.summary}
              </div>
            </div>
            : null}
          {projectQuery.data.project.archived !== true ? (
            <>
              <Row>
                <Button
                  onClick={archiveProject}
                  label={
                    <>
                      <FontAwesomeIcon icon={faTrashAlt} />
                      Archive Project
                    </>
                  }
                  isSecondary
                  variant={ButtonVariant.narrow}
                />
              </Row>
            </>
          ) : null}
          <Structure.LColumn10>
            <Typography.SubHeader>Status Options</Typography.SubHeader>
            <div
              style={{ display: "flex", flexDirection: "row", gap: 20 }}
            >
              <div style={{ flexGrow: 1 }}>
                <Table
                  header={["Color", "Label", "", "", "", ""]}
                  $gridTemplateColumns="min-content auto min-content min-content min-content min-content"
                >
                  {statuses.map((status) => (
                    <TableRow key={status.uuid}>
                      <TableCell>
                        {Object.entries(TaskColors).map(([key, color]) => (
                          <div
                            key={key}
                            style={{
                              backgroundColor: color,
                              color: "white",
                              border: `1px solid ${status.color === key ? "white" : color}`,
                              width: 19,
                              height: 19,
                              fontSize: 12.5,
                              borderRadius: 5,
                              display: "flex", justifyContent: "center", alignItems: "center"
                            }}
                            onClick={() => {
                              const newStatuses = statuses.map(s => {
                                if (s.uuid === status.uuid) {
                                  return {
                                    ...s,
                                    color: key as TaskColorsEnum,
                                  };
                                }
                                return s;
                              });
                              setStatuses(newStatuses);
                            }}
                          >
                            {status.color === key ?
                              <FontAwesomeIcon icon={faCheck} />
                              : null}
                          </div>
                        ))}
                      </TableCell>
                      <EditableTableCell
                        value={status.label}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                          const newStatuses = statuses.map(s => {
                            if (s.uuid === status.uuid) {
                              return {
                                ...s,
                                label: evt.target.value,
                              };
                            }
                            return s;
                          });
                          setStatuses(newStatuses);
                        }}
                      />
                      <TableCell>
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 5,
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={status.default === true}
                            onChange={evt => {
                              const newStatuses = statuses.map(s => {
                                return {
                                  ...s,
                                  default: s.uuid === status.uuid,
                                };
                              });
                              setStatuses(newStatuses);
                            }}
                          />
                          <div>Default Status</div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 5,
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={status.closed === true}
                            onChange={evt => {
                              const newStatuses = statuses.map(s => {
                                if (s.uuid === status.uuid) {
                                  return {
                                    ...s,
                                    closed: evt.target.checked,
                                  };
                                }

                                return s;
                              });
                              setStatuses(newStatuses);
                            }}
                          />
                          <div>Closed</div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 5,
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={status.hideFromKanban === true}
                            onChange={evt => {
                              const newStatuses = statuses.map(s => {
                                if (s.uuid === status.uuid) {
                                  return {
                                    ...s,
                                    hideFromKanban: evt.target.checked,
                                  };
                                }

                                return s;
                              });
                              setStatuses(newStatuses);
                            }}
                          />
                          <div>Hide from Kanban</div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <CircleButton
                          square
                          icon={faTrash}
                          size={20}
                          onClick={() => {
                            setStatuses(statuses => statuses.filter(s => s.uuid !== status.uuid));
                          }}
                          secondary
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
                <Spacer height={10} />
                <Button
                  label={
                    <>
                      <FontAwesomeIcon icon={faPlus} />
                      New Status
                    </>
                  }
                  onClick={() => {
                    setStatuses(s => ([...s, {
                      ...HIYLLO_DEFAULT_STATUSES[0],
                      uuid: crypto.randomUUID()
                    }]));
                  }}
                  variant={ButtonVariant.narrow}
                  autoWidth
                  isSecondary
                />
                <AnimateChangeInHeight>
                  {statuses != _statuses ?
                    <>
                      <Spacer height={10} />
                      <Button
                        onClick={saveCustomStatuses}
                        label="Save Custom Statuses"
                        autoWidth
                      />
                    </>
                    : <div />}
                </AnimateChangeInHeight>
              </div>
              <InfoCard
                label="What are custom statuses on a project?"
                title="Custom Statuses"
                description={[
                  <>
                    You can customize the statuses that can be selected on a project.
                  </>,
                  <>
                    If a status is the default, new tasks will be created with that status unless the creator specifies a different status.
                  </>,
                  <>
                    If a status is marked as closed, tasks with that status will be considered closed, and things like due date reminders will not be sent.
                  </>
                ]}
              />
            </div>
          </Structure.LColumn10>

        </Structure.LColumn20>
      </Container>
    );
  },
);
