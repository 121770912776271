import React from "react";
import { Input, useInputControl } from "@hiyllo/ux/input";
import { styled } from "@hiyllo/ux/styled";
import "moment";
import moment from "moment-timezone";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { useCreateEventController } from "@hiyllo/omni-calendar/main/src/create-event-controller";

import * as FetchUserDataBP from "../../../blueprints/continuity/people/lookup-person-by-userid";
import * as CreateEventBP from "../../../blueprints/calendar/create-event";
import { seamlessClient } from "../../../seamless-client";
import { useNavigate } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { useSelf } from "@hiyllo/omni-continuity";
import { VisualPreviewWithGuests } from "./components/visual-preview-with-guests";
import {
  EventTypeEnum,
  InternalInvitee,
  type EventGuestInviteeType,
  type EventGuestType,
} from "../../../types/calendar/calendar-event";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TimeInput } from "@hiyllo/ux/time-input";
import { CheckboxInput } from "@hiyllo/ux/checkbox-input";
import { EventTypeSelect } from "./components/event-type-select";
import {
  RepeatSettingInput,
  type RepeatSettingInputRef,
} from "./week-view/components/repeat-setting-input";
import { useSimpleAutosave } from "../../../platform/simple-autosave";
import {
  GuestManagementView,
  type GuestManagementViewRefType,
} from "@hiyllo/omni-calendar";
import { motion } from "framer-motion";
import { createGuestFromUserId } from "@hiyllo/omni-calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationPin,
  faTriangleExclamation,
} from "@fortawesome/pro-light-svg-icons";
import { GOOGLE_MAPS_API_KEY } from "../../google/maps/env";
import { AddressInput, PlacesProvider } from "@hiyllo/ux/address-input";
import { useIsSolo } from "../../../platform/hooks/use-is-solo";
import { ErrorText } from "../../../ux/error";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";
import { RepeatingConfigAsEnum } from "../../../types/calendar/repeating";

const PageContainer = styled("div", ({ $theme }) => ({
  padding: 30,
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  height: "calc(100% - 60px)",
  gap: 15,
  color: $theme.foreground
}));
const CenteredRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  flexWrap: "wrap",
});
const Spacer = styled("div", { height: 15 });

const WarningCard = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  // background: "#ff9800",
  color: "#ff9800",
  border: "2px #ff9800 solid",
  padding: 10,
  borderRadius: 10,
}));

export function resolveEndDate(date: string, endTime: string): moment.Moment {
  if (endTime === "00:00") {
    return moment(date + endTime, "YYYY-MM-DDHH:mm").endOf("day");
  }

  return moment(date + endTime, "YYYY-MM-DDHH:mm");
}

export const CreateEventView = React.memo(function CreateEventView(props: {
  timing?: { start: Date; end: Date };
}): JSX.Element {
  const createEventMutation =
    seamlessClient.useMutation<CreateEventBP.Plug>(CreateEventBP);
  const guestsPaneRef = React.useRef<GuestManagementViewRefType>(null);
  const notificationsEnabledRef = React.useRef<HTMLInputElement>(null);
  const titleInputRef = useInputControl({});
  const [toDate, setToDate] = React.useState<string>("");
  const {
    date,
    startTime,
    endTime,
    allDay,
    timing,

    onChangeDate,
    onChangeStartTime,
    onChangeEndTime,
    onChangeAllDay,
  } = useCreateEventController({
    timing: props.timing,
  });

  const [fromTo, setFromTo] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [location, setLocation] = React.useState<string | null>(null);
  const navigate = useNavigate();
  const self = useSelf();
  const [eventType, setEventType] = React.useState<EventTypeEnum>(
    EventTypeEnum.event,
  );
  const repeatSettingInputRef = React.useRef<RepeatSettingInputRef>(null);
  const descriptionAutosave = useSimpleAutosave(
    "calendar/create-event/description",
  );
  const descriptionInputRef = useInputControl({
    defaultValue: descriptionAutosave.defaultValue,
    onChangeValue: descriptionAutosave.update
  });
  const defaultSelfGuest = React.useRef(
    createGuestFromUserId(self.userId),
  ).current;
  const [guests, setGuests] = React.useState<EventGuestType[]>([
    defaultSelfGuest,
  ]);
  const fetchUserDataMutation = seamlessClient.useMutation<FetchUserDataBP.Plug>(FetchUserDataBP);

  const [autogeneratedTitle, setAutogeneratedTitle] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (guests.length === 2 && guests[1].invitee.type === "internal") {
      void (async () => {
        const users = await Promise.all(
          guests.map(g => fetchUserDataMutation.call({ userId: (g.invitee as InternalInvitee).userId }))
        );

        setAutogeneratedTitle(`${users[1].profile.name} / ${users[0].profile.name}`);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guests]);

  const onClose = React.useCallback(
    (eventUUID?: string, _date?: Date) => {
      descriptionAutosave.clear();
      navigate({
        feature: Features.calendar,
        params: {
          view: "week-agenda",
          weekOf:
            _date != null
              ? _date
              : date !== ""
                ? moment(date, "YYYY-MM-DD").toDate()
                : new Date(),
          ...(eventUUID == null ? {} : { scrollToEvent: eventUUID }),
        },
      });
    },
    [date, navigate, descriptionAutosave],
  );

  const onSubmit = React.useCallback((): void => {
    if (
      timing == null
    ) {
      return;
    }

    if (guestsPaneRef.current == null && eventType !== EventTypeEnum.busy) {
      return;
    }

    const invitees: Array<{
      invitee: EventGuestInviteeType;
      optional: boolean;
    }> =
      eventType === EventTypeEnum.busy
        ? [
          {
            invitee: { type: "internal", userId: self.userId },
            optional: false,
          },
        ]
        : guestsPaneRef.current!.extractGuests();
    let title = titleInputRef.valueRef.current;
    const description = descriptionInputRef.valueRef.current;

    if (title.length < 1 && autogeneratedTitle != null) {
      title = autogeneratedTitle;
    }

    if (title.length < 1 && eventType !== "busy") {
      return alert("Add a title!");
    }


    if (fromTo && (toDate === "" || !moment(toDate, "YYYY-MM-DD").isValid())) {
      return alert("Invalid end date");
    }

    if (fromTo && moment(toDate, "YYYY-MM-DD").isBefore(moment(date, "YYYY-MM-DD"))) {
      return alert("End date cannot be before start date");
    }

    void createEventMutation
      .call({
        type: eventType,
        title: title === "" ? "Busy" : title,
        description,
        notificationsEnabled:
          eventType === EventTypeEnum.busy
            ? false
            : notificationsEnabledRef.current!.checked,
        invitees: [...invitees],
        repeats:
          fromTo ?
            {
              schedule: {
                type: "daily",
                every: { days: 1 },
                until: moment(toDate, "YYYY-MM-DD").add(0, "days").toDate(),
              },
              endOn: { date: moment(toDate, "YYYY-MM-DD").add(0, "days").toDate() },
              startOn: {
                date: timing._computed.start
              },
              isRoot: true,
              as: RepeatingConfigAsEnum.fromTo
            }
            :
            repeatSettingInputRef.current?.extract(moment(date).toDate()) ?? null,
        notify: true,
        timing,
        location:
          location == null || location.length < 1
            ? null
            : { input: location, coords: null },
      })
      .then((res) => {
        descriptionAutosave.clear();
        onClose(res.eventUUID, moment(date).toDate());
      })
      .catch((err) => {
        console.error(err);
        alert(err.message);
      });
  }, [timing, eventType, self.userId, fromTo, toDate, date, createEventMutation, location, descriptionAutosave, onClose]);

  const onEventTypeChanged = React.useCallback((type: EventTypeEnum) => {
    setEventType(type);
  }, []);

  const isSolo = useIsSolo();

  return (
    <PageContainer>
      <div style={{ flexGrow: 1 }}>
        <CircleButton icon={faTimes} onClick={() => onClose()} size={30} secondary square />

        <div style={{ height: 20 }} />

        <EventTypeSelect
          eventType={eventType}
          onEventTypeChanged={onEventTypeChanged}
        />

        <div style={{ height: 20 }} />

        <Input
          fullWidth
          placeholder={eventType === "busy" ? "<Busy>" : (autogeneratedTitle ?? "Event Title...")}
          {...titleInputRef.inputProps}
        />

        <Spacer />

        <CenteredRow>
          <PlacesProvider apiKey={GOOGLE_MAPS_API_KEY}>
            <AddressInput
              startEnhancer={
                <div style={{ paddingRight: "0.5em" }}>
                  <FontAwesomeIcon icon={faLocationPin} fixedWidth />
                </div>
              }
              placeholder="Location..."
              onChangeValue={setLocation}
              value={location ?? ""}
              fullWidth
            />
          </PlacesProvider>
        </CenteredRow>
        <Spacer />

        <CenteredRow>
          <Input
            type="date"
            outerStyle={{ width: 140 }}
            value={date}
            onChange={(evt) => onChangeDate(evt.target.value)}
          />
          {fromTo ?
            <Input
              type="date"
              outerStyle={{ width: 140 }}
              value={toDate}
              onChange={(evt) => setToDate(evt.target.value)}
            />
            : null}
          {allDay ?
            <CheckboxInput
              label="From -> To"
              value={fromTo}
              onChange={setFromTo}
            />
            : null}
          <CheckboxInput
            label="All Day"
            value={allDay}
            onChange={onChangeAllDay}
          />
          {!allDay ? (
            <>
              <TimeInput
                value={startTime ?? "00:00"}
                onChangeValue={onChangeStartTime}
                minWidth
              />
              to
              <TimeInput
                value={endTime ?? "00:15"}
                onChangeValue={onChangeEndTime}
                useEndingTimeOptions
                minWidth
              />
            </>
          ) : null}
          {!fromTo ?
            <div style={{ width: 200, flexGrow: 1 }}>
              <RepeatSettingInput ref={repeatSettingInputRef} />
            </div>
            : null}
        </CenteredRow>

        <Spacer />

        <AnimateChangeInHeight>
          {timing != null && timing?._computed.start < new Date() ? (
            <>
              <WarningCard>
                <FontAwesomeIcon icon={faTriangleExclamation} />
                <div>
                  <b>Heads up!</b> You&apos;re about to create an event in the
                  past.
                </div>
              </WarningCard>
              <Spacer />
            </>
          ) : null}
        </AnimateChangeInHeight>

        <Input
          fullWidth
          multiline
          inputStyle={{ minHeight: 150 }}
          placeholder="Add some extra details..."
          {...descriptionInputRef.inputProps}
        />

        <Spacer />

        {eventType === "event" ? (
          <>
            <Spacer />
            <label
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
                cursor: "pointer",
              }}
            >
              <input
                ref={notificationsEnabledRef}
                type="checkbox"
                defaultChecked={true}
              />
              Enable Reminders
            </label>
            <Spacer />
          </>
        ) : null}

        {errorMessage != null ? (
          <div style={{ paddingBottom: 10 }}>
            <ErrorText message={errorMessage} />
          </div>
        ) : null}

        <CircleButton
          onClick={onSubmit}
          isLoading={createEventMutation.isLoading}
          size={50}
        />
      </div>
      <motion.div
        style={{ overflow: "hidden" }}
        animate={{ width: eventType === "event" ? 300 : 0 }}
        exit={{ width: 0 }}
      >
        <GuestManagementView
          ref={guestsPaneRef}
          defaultGuests={[defaultSelfGuest]}
          onChanged={setGuests}
        />
      </motion.div>
      <VisualPreviewWithGuests
        startTime={startTime}
        endTime={endTime}
        date={date}
        invitees={guests.map((g) => g.invitee)}
        onChangeDate={onChangeDate}
      />
    </PageContainer>
  );
});
