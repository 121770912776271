import { faGlobe } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWrappedPopOver } from "@hiyllo/ux/context-menu";
import { type OptionType, Select } from "@hiyllo/ux/select";
import { Card } from "@hiyllo/ux/surface";
import { Typography } from "@hiyllo/ux/typography";
import React from "react";
import moment from "moment-timezone";
import { TimezoneContext } from "../contexts";
import { useTimezone } from "../hooks/use-timezone";

const zones = moment.tz.names();

const options: OptionType[] = zones.map((zone) => ({
  label: zone,
  value: zone,
}));

export const TimeZoneSelector = React.memo(function TimeZoneSelector(props: {
  setTimezone: (zone: string) => void;
}): JSX.Element {
  const { open, CXMenuContainer, isOpen, ref } =
    useWrappedPopOver<HTMLDivElement>({
      offset: { top: 40, right: 0 },
      displayMode: "fixed",
    });
  const current = useTimezone();

  console.log("-> 11", isOpen);

  return (
    <>
      <div
        ref={ref}
        onClick={open}
        style={{
          cursor: "pointer",
          pointerEvents: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: 40,
            width: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
          }}
        >
          <FontAwesomeIcon icon={faGlobe} />
        </div>
        {current !== moment.tz.guess() ? <div>{current}</div> : null}
      </div>
      <CXMenuContainer>
        <div onClick={(evt) => evt.stopPropagation()}>
          <Card $color="background2">
            <div style={{ minWidth: 300 }}>
              <Typography.SubHeader>
                <FontAwesomeIcon icon={faGlobe} />
                &nbsp;&nbsp;Switch Timezone
              </Typography.SubHeader>
              <div style={{ height: 5 }} />
              <Select
                options={options}
                value={current}
                fullWidth
                onChangeValue={(v) => props.setTimezone(v as string)}
              />
            </div>
          </Card>
        </div>
      </CXMenuContainer>
    </>
  );
});
